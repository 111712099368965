import { css } from "glamor"
import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import {
  FaSitemap,
  FaUser,
  FaUsers,
  FaUserCog,
  FaChartLine,
  FaSyncAlt,
  FaArrowLeft,
} from "react-icons/fa"
import { AiFillDashboard } from "react-icons/ai"
import { MdWork } from "react-icons/md"
import { RiLogoutBoxLine } from "react-icons/ri"
import { ServiceDisplayName } from "../../utils/service-mappings"
import { change_gravatar } from "../../utils/common"
import { useUserOrgs } from "../../hooks/use-user"
import { useServiceLink } from "../../hooks/use-servicelink"
import { sortBy } from "lodash"
import { FcSettings } from "react-icons/all"
import { fetch_get } from "../../utils/fetch"

function ServiceIconDropdown(props) {
  const srcPath = `/${props.serviceName}.svg`
  const text = ServiceDisplayName(props.serviceName)
  if (props.link !== "false") {
    var link = props.serviceLink[props.serviceName]
  }

  return props.serviceName !== "irc" ? (
    <DropdownItem className="px-1 p-0">
      <NavLink href={link} rel="noreferrer" target={props.target || "_blank"}>
        <img
          className={`img-fluid d-inline-block mr-2`}
          src={srcPath}
          {...css(styles.serviceIconImage)}
          alt={props.serviceName}
          height="20"
        />
        {text}
      </NavLink>
    </DropdownItem>
  ) : (
    <>
      <DropdownItem header style={{ padding: "0px 12px" }}>
        <span class="dropdown-toggle nav-link" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <img
            className={`img-fluid d-inline-block mr-2`}
            src={srcPath}
            {...css(styles.serviceIconImage)}
            alt={props.serviceName}
            height="20"
          />
          {text}
        </span>
      </DropdownItem>
      <DropdownItem
        tag={Link}
        to="https://webirc.codelinaro.org/"
        target="_blank"
        style={{ paddingLeft: "50px", color: "rgba(0, 0, 0, 0.5)" }}
      >
        Web Client
      </DropdownItem>
      <DropdownItem
        tag={Link}
        to="https://irc.codelinaro.org:8443/"
        target="_blank"
        style={{ paddingLeft: "50px", color: "rgba(0, 0, 0, 0.5)" }}
      >
        Proxy
      </DropdownItem>
    </>
  )
}

function DropDownItems(props) {
  const { logout } = useAuth0()
  const {
    currentOrg: {
      role: { isProjectAdmin, isUserAdmin, isSystemAdmin, isSuperAdmin },
    },
  } = useUserOrgs()
  return (
    <>
      {props.downType === "admin" ? (
        <>
          {isUserAdmin && (
            <DropdownItem tag={Link} to="/secure/useradmin/">
              <FaUser className=" mr-2" />
              Manage Users
            </DropdownItem>
          )}
          {isProjectAdmin && (
            <DropdownItem tag={Link} to="/secure/projectadmin/">
              <MdWork className="mr-2" />
              Project Management
            </DropdownItem>
          )}
          {isSystemAdmin && (
            <DropdownItem tag={Link} to="/metrics/">
              <FaChartLine className=" mr-2" />
              Metrics
            </DropdownItem>
          )}
          {isSuperAdmin && (
            <DropdownItem tag={Link} to="/secure/organizations/">
              <FaSitemap className=" mr-2" />
              Manage Organizations
            </DropdownItem>
          )}
          {isSuperAdmin && (
            <DropdownItem tag={Link} to="/secure/system-settings/">
              <FcSettings className=" mr-2" />
              System Settings
            </DropdownItem>
          )}
          {isSuperAdmin && (
            <DropdownItem tag={Link} to="/admin/accounts">
              <FaSitemap className=" mr-2" />
              Account Usage
            </DropdownItem>
          )}
          {(isSuperAdmin || isSystemAdmin) && (
            <DropdownItem tag={Link} to="/admin/data-transfer-management">
              <FaSitemap className=" mr-2" />
              Data transfer Management
            </DropdownItem>
          )}
          {isSuperAdmin && (
            <DropdownItem tag={Link} to="/admin/ci">
              <FaSitemap className=" mr-2" />
              CI Usage
            </DropdownItem>
          )}
          {isSuperAdmin && (
            <DropdownItem tag={Link} to="/admin/queues">
              <FaSitemap className=" mr-2" />
              Queues
            </DropdownItem>
          )}

          {isSuperAdmin && (
            <DropdownItem tag={Link} to="/secure/teamadmin/">
              <FaUsers className=" mr-2" />
              Manage Teams
            </DropdownItem>
          )}
        </>
      ) : (
        <>
          <DropdownItem tag={Link} to="/secure/settings/">
            <FaUserCog className="mr-2" />
            Account Settings
          </DropdownItem>
          {/* <DropdownItem tag={Link} to="/secure/summary">
            <FaUserCog className="mr-2" />
            Data Transfer Management
          </DropdownItem> */}
          {props.beLink && (
            <DropdownItem tag={Link} to={props.beLink} target="_blank">
              <img src="/billing-portal.svg" style={{ width: 16, height: 16 }} className="mr-2" />
              Billing Portal
            </DropdownItem>
          )}
          {isSuperAdmin ? null : (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div
                style={{ width: 194 }}
                onClick={() => {
                  props.onClickSwitchOrg()
                }}
              >
                <DropdownItem className="switch" tag="button" header>
                  <FaSyncAlt className="mr-2" />
                  Switch Organization
                </DropdownItem>
              </div>
            </>
          )}
          <DropdownItem
            onClick={event => {
              event.preventDefault()
              logout({ returnTo: `${process.env.AUTH0_LOGOUT}` })
            }}
          >
            <RiLogoutBoxLine className="mr-2" />
            Logout
          </DropdownItem>
        </>
      )}
    </>
  )
}

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false)
  const [isDropdown, setIsDropdown] = useState(false)
  const [isClickSwitch, setIsClickSwitch] = useState(false)
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0()
  const toggle = () => setIsOpen(!isOpen)
  const {
    orgs,
    loading,
    currentOrg: {
      index,
      name,
      role: { isProjectAdmin, isUserAdmin, isSuperAdmin },
    },
  } = useUserOrgs()
  const [serviceList, setServiceList] = useState([])
  const { serviceLink, loadingServiceLink } = useServiceLink()
  const [isOperate, setIsOperate] = useState(false)
  const [beLink, setBeLink] = useState("")
  useEffect(() => {
    let mounted = true
    let services = []
    if (isAuthenticated && !loading) {
      let data = user["https://api.codelinaro.org/services"] || {}
      if (user.picture) {
        change_gravatar("header_garvatar", user.picture)
      }
      Object.keys(data).forEach(function (key) {
        if (data[key]["status"] === "active") {
          services.push(key)
        }
      })
      if (mounted) {
        services = sortBy(services, function (service) {
          return service === "servicedesk" ? 0 : 1
        })
        setServiceList(services)
      }
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(
            isSuperAdmin
              ? `be/billing_portal/menu/users`
              : `be/billing_portal/menu/users?index=${index}`,
            accessToken
          )
            .then(response => {
              setBeLink(response.link)
            })
            .catch(error => {
              console.log(`User is not associated with BE`)
            })
        )
        .catch(error => {})
      if (isSuperAdmin) return
      getAccessTokenSilently()
        .then(accessToken =>
          fetch_get(`CI/user/enabled?index=${index}`, accessToken)
            .then(response => {
              setIsOperate(response.ci_enabled)
            })
            .catch(error => {
              console.log(`Error while getting projects have CI enabled: ${error}`)
            })
        )
        .catch(error => {
          console.log(`Error while getting acdcess token: ${error}`)
        })
    }
    return function cleanup() {
      mounted = false
    }
  }, [user, isAuthenticated, loading])

  return (
    <Navbar color="white" className="top_level" light expand="md">
      <div class="container">
        <NavbarBrand tag={Link} to="/">
          <img {...css(styles.logo)} src={`/Code_Linaro.png`} alt="CodeLinaro logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link
                to="/projects/"
                className="nav-link"
                activeClassName={`${css(styles.activeLink)}`}
              >
                Projects
              </Link>
            </NavItem>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                About
              </DropdownToggle>
              <DropdownMenu right {...css(styles.dropdown)}>
                {/* <DropdownItem tag={Link} to="/about/">
                  What is CodeLinaro?
                </DropdownItem> */}
                <DropdownItem tag={Link} to="/faq/">
                  FAQ
                </DropdownItem>
                <DropdownItem tag={Link} to="https://status.codelinaro.org/" target="_blank">
                  Systems Status
                </DropdownItem>
                {!isLoading ? (
                  isAuthenticated ? (
                    isSuperAdmin ? (
                      <DropdownItem tag={Link} to="/inner-support/">
                        Request Support
                      </DropdownItem>
                    ) : (
                      <DropdownItem tag={Link} to="/inner-support/">
                        Request Support
                      </DropdownItem>
                    )
                  ) : (
                    <DropdownItem tag={Link} to="/support/">
                      Request Support
                    </DropdownItem>
                  )
                ) : (
                  <></>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
            {!isLoading ? (
              isAuthenticated ? (
                <>
                  <NavItem>
                    <Link
                      to="/dashboard/"
                      className="nav-link"
                      activeClassName={`${css(styles.activeLink)}`}
                    >
                      Dashboard
                    </Link>
                  </NavItem>
                  {serviceList.length === 0 || loadingServiceLink ? null : (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Services
                      </DropdownToggle>
                      <DropdownMenu right {...css(styles.dropdown)}>
                        {serviceList.map(s => (
                          <ServiceIconDropdown serviceName={s} key={s} serviceLink={serviceLink} />
                        ))}
                        {(isSuperAdmin || (!isSuperAdmin && isOperate)) && (
                          <DropdownItem className="px-1 p-0">
                            <NavLink
                              rel="noreferrer"
                              onClick={() => {
                                navigate("/ci/dashboard/")
                              }}
                            >
                              <img
                                class="img-fluid d-inline-block mr-2"
                                src="/CI.svg"
                                data-css-1x9hoqh=""
                                alt="CI"
                                height="20"
                              />
                              CI Dashboard
                            </NavLink>
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                  {(isUserAdmin || isProjectAdmin || isSuperAdmin) && (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Administrator
                      </DropdownToggle>
                      <DropdownMenu right {...css(styles.dropdown)}>
                        <DropDownItems downType="admin" />
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                  <UncontrolledDropdown
                    nav
                    inNavbar
                    isOpen={isDropdown}
                    toggle={() => {
                      if (!isDropdown) {
                        setIsClickSwitch(false)
                      }
                      setIsDropdown(!isDropdown)
                    }}
                  >
                    <DropdownToggle nav caret>
                      <img
                        src={user.picture}
                        {...css(styles.avatar)}
                        alt={user.name}
                        id="header_garvatar"
                      />
                      <span className="ml-1 font-weight-bold">
                        {isSuperAdmin ? user.name : name}
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right {...css(styles.dropdown)}>
                      <div {...css(isClickSwitch ? styles.switchRight : styles.switchLeft)}>
                        <ul {...css(styles.switchContent)}>
                          <DropDownItems
                            onClickSwitchOrg={() => {
                              setIsClickSwitch(true)
                            }}
                            beLink={beLink}
                          />
                        </ul>
                        <ul {...css(styles.switchContent)}>
                          <FaArrowLeft
                            style={{ cursor: "pointer", margin: 10 }}
                            onClick={() => {
                              setIsClickSwitch(false)
                            }}
                          />
                          <div style={{ textAlign: "center", display: "inline-block" }}>
                            Switch organization
                          </div>
                          {orgs.map(o => (
                            <DropdownItem
                              tag="button"
                              onClick={() => {
                                localStorage.setItem(
                                  user["https://api.codelinaro.org/username"],
                                  o.index
                                )
                                navigate("/dashboard/")
                              }}
                              style={{
                                borderLeft: index === o.index ? "3px solid #9c3" : 0,
                              }}
                              disabled={index === o.index}
                            >
                              {o.name}
                            </DropdownItem>
                          ))}
                        </ul>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              ) : (
                <NavItem>
                  <NavLink href="/dashboard/">Login</NavLink>
                </NavItem>
              )
            ) : (
              <>
                <NavItem className="nav-link">
                  Loading...
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  >
                    <span className="sr-only">Loading...</span>
                  </span>
                </NavItem>
              </>
            )}
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  )
}

const styles = {
  logo: {
    height: "35px",
  },
  avatar: {
    height: "40px",
    borderRadius: "50%",
    marginLeft: "5px",
  },
  activeLink: {
    borderBottom: " 5px solid #9c3",
  },
  dropdown: {
    border: "1px solid #9c3",
    borderRadius: "0px",
    WebkitBoxShadow: "0px 0px 20px -7px rgba(0, 0, 0, 0.75)",
    MozBoxShadow: "0px 0px 20px -7px rgba(0, 0, 0, 0.75)",
    boxShadow: "0px 0px 20px -7px rgba(0, 0, 0, 0.75)",
    overflow: "hidden !important", //TODO
  },
  serviceIconImage: {
    height: "25px",
    marginLeft: "5px",
  },
  switchLeft: {
    whiteSpace: "nowrap",
    display: "block",
    transition: "transform 0.2s ease-in-out 0s",
    width: 194,
  },
  switchRight: {
    whiteSpace: "nowrap",
    display: "block",
    transition: "transform 0.2s ease-in-out 0s",
    transform: "translateX(-100%)",
    width: 196,
  },
  switchContent: {
    display: "inline-block",
    position: "relative",
    verticalAlign: "top",
    listStyle: "none",
    padding: "0px",
    width: "100%",
  },
}
