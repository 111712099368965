import { css } from "glamor"
import React from "react"
import { Container } from "reactstrap"
import { Link } from "gatsby"
import CookieLaw  from "./cookielaw"

export default function Footer(props) {
  return (
    <Container
      fluid
      className={`text-dark`}
      {...css(styles.footer)}
      {...css(
        props.pageName === "homepage"
          ? { opacity: 0.7, color: "#000 !important", fontWeight: 600 }
          : {}
      )}
    >
      <Container className="text-center py-4">
        <CookieLaw/>
        <div>
          Copyright &copy; {new Date().getFullYear()} Linaro Limited |&nbsp;
          <Link
            className="text-dark"
            to="/about/"
            {...css(props.pageName === "homepage" ? { color: "#000 !important" } : {})}
          >
            About
          </Link>
          &nbsp;|&nbsp;
          <Link
            className="text-dark"
            to="/policies/terms-of-service.html"
            {...css(props.pageName === "homepage" ? { color: "#000 !important" } : {})}
          >
            Policies
          </Link>
          &nbsp;|&nbsp;
          <Link
            className="text-dark"
            to="/contact/"
            {...css(props.pageName === "homepage" ? { color: "#000 !important" } : {})}
          >
            Contact
          </Link>
        </div>
      </Container>
    </Container>
  )
}

const styles = {
  footer: {
    position: "absolute",
    bottom: 0,
    backgroundColor: "#eee",
  },
}
