import React, { useState } from "react"
import { css } from "glamor"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  NavbarBrand,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { MdClose, MdMenu } from "react-icons/md"

function DropDownItems(props) {
  return (
    <>
      <DropdownItem tag={NavLink} href="https://www.96boards.ai" target="_blank">
        96Boards.ai
      </DropdownItem>
      <DropdownItem tag={NavLink} href="https://www.linaro.cloud" target="_blank">
        Developer Cloud
      </DropdownItem>
      <DropdownItem tag={NavLink} href="https://www.devicetree.org" target="_blank">
        Device Tree
      </DropdownItem>
      <DropdownItem tag={NavLink} href="https://www.mlplatform.org" target="_blank">
        ML PLatform
      </DropdownItem>
      <DropdownItem tag={NavLink} href="https://www.openampproject.org" target="_blank">
        OpenAMP
      </DropdownItem>
      <DropdownItem tag={NavLink} href="https://www.op-tee.org" target="_blank">
        OP-TEE
      </DropdownItem>
      <DropdownItem tag={NavLink} href="https://www.trustedfirmware.org" target="_blank">
        Trusted Firmware
      </DropdownItem>
    </>
  )
}

export default function UniversalNavBar() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const menuToggle = () => setDropdownOpen(prevState => !prevState)

  return (
    <Navbar color="primary" className="text-dark py-0 top_level" light expand="md">
      <div class="container">
        <NavbarBrand tag={NavLink} to="/"></NavbarBrand>
        <NavbarToggler onClick={toggle} className={`${styles.nav}`} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {/* <NavItem>
              <NavLink  key={Math.random()}
                href="https://www.linaro.org"
                {...css(styles.navItem, styles.activeLink)}
                className={`nav-link active`}
                target="_blank"
              >
                Linaro
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink  key={Math.random()}
                href="https://connect.linaro.org"
                {...css(styles.navItem, styles.navLink)}
                className={`nav-link`}
                target="_blank"
              >
                Connect
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink  key={Math.random()}
                {...css(styles.navItem, styles.navLink)}
                href="https://www.96boards.org"
                className={`nav-link`}
                target="_blank"
              >
                96Boards
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink  key={Math.random()}
                {...css(styles.navItem, styles.navLink)}
                href="https://twitter.com/LinaroOrg"
                className={`nav-link`}
                target="_blank"
              >
                <img height="16" src={`/twitter_w.svg`} alt="twitter" />
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink  key={Math.random()}
                {...css(styles.navItem, styles.navLink)}
                href="http://facebook.com/LinaroOrg"
                className={`nav-link`}
                target="_blank"
              >
                <img height="16" src={`/facebook_w.svg`} alt="facebook" />
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink  key={Math.random()}
                {...css(styles.navItem, styles.navLink)}
                href="https://www.linkedin.com/company/1026961"
                className={`nav-link`}
                target="_blank"
              >
                <img height="16" src={`/linkedin_w.svg`} alt="linkedin" />
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink  key={Math.random()}
                {...css(styles.navItem, styles.navLink)}
                href="https://www.youtube.com/LinaroOrg"
                className={`nav-link`}
                target="_blank"
              >
                <img height="16" src={`/youtube_w.svg`} alt="youtube" />
              </NavLink>
            </NavItem> */}
            {/* <Dropdown isOpen={dropdownOpen} toggle={menuToggle} inNavbar>
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
                style={{ fontSize: "30px", lineHeight: "36px" }}
              >
                {dropdownOpen ? <MdClose /> : <MdMenu />}
              </DropdownToggle>
              <DropdownMenu right {...css(styles.dropdown)}>
                <DropDownItems />
              </DropdownMenu>
            </Dropdown> */}
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  )
}

const styles = {
  nav: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  activeLink: {
    backgroundColor: "#575757",
    color: "#fff !important",
  },
  navLink: {
    color: " #000 !important",
  },
  navItem: {
    textDecoration: "none",
    display: "inline-block",
    padding: "9px 20px !important",
    textTransform: "uppercase",
    fontSize: "16px",
    position: "relative",
  },
  dropdown: {
    border: "1px solid #9c3",
    borderRadius: "0px",
    WebkitBoxShadow: "0px 0px 20px -7px rgba(0, 0, 0, 0.75)",
    MozBoxShadow: "0px 0px 20px -7px rgba(0, 0, 0, 0.75)",
    boxShadow: "0px 0px 20px -7px rgba(0, 0, 0, 0.75)",
  },
}
