import { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { fetch_get } from "../utils/fetch"

export const useServiceLink = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0()
  const [state, setState] = useState({
    loadingServiceLink: true,
    error: null,
    serviceLink: {},
  })

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      getAccessTokenSilently()
        .then(accessToken => fetch_get(`ui/services`, accessToken))
        .then(response => {
          if (response) {
            setState({
              loadingServiceLink: false,
              serviceLink: response,
            })
          }
        })
    }
  }, [isAuthenticated, isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  return state
}
