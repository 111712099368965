import React, { useEffect } from "react"
import "../../styles/theme.scss"
import NavBar from "../navbar/nav-bar"
import UniversalNavBar from "../universal-navbar/universal-navbar"
import Footer from "../footer/footer"
import { Helmet } from "react-helmet"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useAuth0 } from "@auth0/auth0-react"
import * as Sentry from "@sentry/gatsby"
import { Loading } from "../../components/loading"
import { withPrefix, Link } from "gatsby"

export default function Layout(props) {
  const { isAuthenticated, user, isLoading } = useAuth0()

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      Sentry.configureScope(function (scope) {
        scope.setUser({ email: user.email })
      })
    }
  }, [isAuthenticated, isLoading, user])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{props.pageTitle} - CodeLinaro</title>
        <script src="/js/html2pdf.bundle.min.js" type="text/javascript" />
        <script src="/js/custom.js" type="text/javascript" />
      </Helmet>
      {/* testign {"." + withPrefix("custom.js")} */}
      <UniversalNavBar />
      <NavBar />
      {props.children}
      <ToastContainer />
      <Footer pageName={props.pageName} />
    </>
  )
}
