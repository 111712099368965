import React, { useEffect, useState } from "react"
import { Container } from "reactstrap"
import { Link } from "gatsby"

export default function CookieLaw(props) {
  var [accepted, setAccepted] = useState(false)
  var nameOfCookie = "codelinaro-website-user_cookie_consent"

  // Create cookie
  function setCookie(cname, cvalue, exdays) {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  }

  // Delete cookie
  function deleteCookie(cname) {
    const d = new Date()
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000)
    let expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=;" + expires + ";path=/"
  }

  // Read cookie
  function getCookie(cname) {
    let name = cname + "="
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(";")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) == " ") {
        c = c.substring(1)
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ""
  }

  // Set cookie consent
  function acceptCookieConsent() {
    deleteCookie(nameOfCookie)
    setCookie(nameOfCookie, 1, 30)
    setAccepted(true)
  }

  useEffect(() => {
    let cookie_consent = getCookie(nameOfCookie)
    if (cookie_consent != "") {
      setAccepted(true)
    } else {
      setAccepted(false)
    }
  }, [])
  return (
    <>
      {!accepted && (
        <div
          id="lawmsg"
          className="alert alert-info alert-dismissible h6 fade show fixed-bottom"
          role="alert"
        >
          &nbsp; We use cookies on this website to distinguish you from other users. &nbsp; We use
          this data to enhance your experience. &nbsp; <br />
          By continuing to use this website you consent to our use of cookies. &nbsp; &nbsp;
          <button
            type="button"
            className="btn btn-sm btn-success"
            onClick={() => acceptCookieConsent()}
          >
            Accept and Close
          </button>
        </div>
      )}
    </>
  )
}
